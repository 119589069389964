import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from "react"
import { navigate, graphql } from "gatsby"
import ReCAPTCHA from "react-google-recaptcha"
import queryString from "query-string"
import Divider from "@material-ui/core/Divider"
import {
  PaymentSummaryTable,
  Header,
  Footer,
  Loader,
  Image,
  SEO,
  DCXButton,
  ShareDialog,
  DCXTable,
  InsuranceShortcutCard,
  InfoDialog,
  CheckoutHeader,
} from "../../components"
import {
  checkoutMoneyImage,
  checkoutDeliveryImage,
  FacePartyIcon,
} from "../../assets"
import { processLead } from "../../api"
import { CarContext, UIContext } from "../../context"
import {
  CalculatePriceFull,
  urlWithSession,
  getSelectedColorName,
  getSelectionsSeparatedString,
  getVersionName,
  buildColorOptions,
  getSelectedOptions,
  decodeSessionString,
  deductSelectedVersion,
  getSelectedModel,
  getMostExpensiveVersionName,
  getCurrentUrlOnMainSiteForSharing,
  parseNumber,
  infoToSessionString,
} from "../../utils"
import {
  useSelectedAccessoriesTotalPrice,
  useSurchargedPrices,
  useSurchargeAmount,
  useIncludeTaxes,
  useInsuranceCompanyName,
  useSpecialDevice,
} from "../../hooks"
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline"
import { SPECIAL_DEVICE, Model, AppContextType, Payment } from "../../types"

type CheckoutProps = {
  data: {
    allFile: {
      nodes: {
        relativePath: string
        absolutePath: string
      }
    }
    allModels: {
      nodes: Model[]
    }
  }
}

const Checkout: React.FC<CheckoutProps> = ({ data }) => {
  const recaptchaRef = useRef(null)

  const appContext = useContext<AppContextType>(CarContext)
  const {
    appLoading,
    selectedVersion,
    setSelectedVersion,
    payment,
    dues,
    carPaymentInfo,
    transmission,
    equipment,
    engine,
    traction,
    color,
    modelsPricesStocks,
    offers,
    checkoutData,
    setCheckoutData,
    selectedOffer,
    financeData,
    windowWidth,
  } = appContext

  const {
    openFinancingDrawer,
    openDetailsDrawer,
    openInsuranceDrawer,
  } = useContext(UIContext)

  const [forStoresPages, storePage] = useSpecialDevice()
  const [forIpadPage] = useState(() => storePage === SPECIAL_DEVICE.ipad)

  const [dialogOpened, setDialog] = useState(false)
  const [insuranceDialogOpen, setInsuranceDialogOpen] = useState(false)
  // Get all car models and Files
  const allModels = data.allModels.nodes
  const allFiles = data.allFile.nodes

  // Check context to determine if sale is present
  const offer = offers?.find(of => of.internalId === selectedOffer)

  // Initialize local state to save current offer or selectedModel and handle loading
  const [selectedModel, setSelectedModel] = useState<Model | null>(null)

  const [loading, setLoading] = useState(true)
  const [deductingSelectedVersion, setDeductingSelectedVersion] = useState(true)

  const [insuranceCompanyName] = useInsuranceCompanyName()

  // Parse query string
  const querys = queryString.parse(
    typeof window !== "undefined" && window.location.search
  )

  //Try to get selectedModel from selectedVersion context state (normal flow) or from session string (shared url)
  const session = decodeSessionString(querys.session)
  if (!selectedModel && selectedVersion?.versionId) {
    const modelFound = getSelectedModel(allModels, selectedVersion)

    setSelectedModel(modelFound)
  } else if (!selectedModel && session && session.modelId) {
    // Deduct model
    const modelFound = allModels.find(model => model.ID === session.modelId)
    setSelectedModel(modelFound)
  }

  // Deduct version and prices, if selectedModel was found
  useEffect(() => {
    // Only when not coming from normal flow, offer is not defined, the internalId is on URL and offers has been fetched
    if (selectedModel && session && modelsPricesStocks && !selectedVersion) {
      // Deduct version and prices
      const deductedSelectedVersion = deductSelectedVersion(
        selectedModel,
        session,
        modelsPricesStocks
      )
      if (deductedSelectedVersion) setSelectedVersion(deductedSelectedVersion)
      setDeductingSelectedVersion(false)
    } else if (!selectedModel && session && modelsPricesStocks) {
      setDeductingSelectedVersion(false)
    }
  }, [
    modelsPricesStocks,
    selectedModel,
    session,
    selectedVersion,
    setSelectedVersion,
  ])

  const selections = { transmission, equipment, engine, traction, color }

  // Stop loader if offer or selectedModel+Version was found
  useEffect(() => {
    if (offer || (selectedModel && selectedVersion)) {
      setLoading(false)
    } else if (!appLoading) {
      if (selectedModel)
        navigate(urlWithSession(`/config/${selectedModel?.slug || ""}`))
      else navigate(urlWithSession("/home"))
    }
  }, [offer, selectedModel, selectedVersion, appLoading])

  let publicPhotoCarUrl = ""
  let colorImagePath = ""
  if (selectedModel) {
    const selectedColorName = getSelectedColorName(selectedModel, { color })
    colorImagePath = selectedModel?.colorGallery
      .find(item => item.colorName.name === selectedColorName)
      .image.url.split("/")
      .pop()
    const theFile = allFiles.find(f => f.relativePath.includes(colorImagePath))
    publicPhotoCarUrl =
      "https://dercocenterx.s3.us-east-2.amazonaws.com/" +
      theFile.absolutePath.split("/").pop()
  }
  if (offer) {
    publicPhotoCarUrl = offer.imageUrl
  }

  const selectedColor =
    !offer &&
    buildColorOptions(selectedModel, selections, modelsPricesStocks, {
      selectedVersion,
    })?.find(opt => opt.selected)

  const deliveryTime = offer
    ? offer.deliveryTime
    : selectedColor?.info.deliveryTime

  const [
    accessoriesTotalPrice,
    selectedAccessories,
  ] = useSelectedAccessoriesTotalPrice()
  const [prices] = useSurchargedPrices({
    prices: offer
      ? {
          priceList: offer.priceList,
          priceCash: offer.priceCash,
          priceCredit: offer.priceCredit,
        }
      : selectedVersion,
    colorId: color,
  })
  const [surchargeAmount] = useSurchargeAmount()
  const [includeTaxes] = useIncludeTaxes()

  /* CALCULATE FINANCING VARIABLES, WITH ADDITIONALS */
  const PaymentParams = CalculatePriceFull({
    appContext,
    offer,
    prices,
    includeTaxes: includeTaxes,
    aditionals: [accessoriesTotalPrice],
  })

  const onSubmit = useCallback(() => {
    setLoading(true)
    // Update checkoutData variable if it has changed in this view from its previous value
    /*if (differences(checkoutData, PaymentParams)) {
      setCheckoutData(prev => ({
        ...prev,
        withCredit: PaymentParams.withCredit,
        carPrice: PaymentParams.finalCarPrice,
        firstPaymentPrice: PaymentParams.firstPaymentPrice,
      }))
    }*/
    // Create lead and save uuid, or update previously created lead
    processLead(forStoresPages ? null : checkoutData.leadUUID, {
      selectedModel: !offer && selectedModel,
      deliveryTime,
      photoCar: publicPhotoCarUrl,
      // client data
      clientData: checkoutData.client,
      // offers
      offer,
      // prices
      prices,
      // dataPayment
      payment: !PaymentParams.withCredit ? Payment.cash : payment,
      dues,
      PaymentParams,
      carPaymentInfo,
      surchargeAmount,
      // dataCar.config
      selections,
      selectedColor,
      // taxes
      includePlatePack: checkoutData.includePlatePack,
      // selectedAccessories
      selectedAccessories,
      accessoriesTotalPrice,
      // insurance
      insuranceSimulationId: checkoutData.insurance?.simulationId,
      // seller
      sellerId: checkoutData.sellerData?.id || null,
    })
      .then(({ data }) => {
        setCheckoutData(prev => ({
          ...prev,
          leadUUID: data.uuid,
        }))
        infoToSessionString({
          leadUUID: data.uuid,
        })
        navigate(urlWithSession("/checkout/step2"))
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        window.alert(
          "Hubo un problema procesando su reserva. Por favor intente más tarde."
        )
      })
  }, [
    forStoresPages,
    PaymentParams,
    carPaymentInfo,
    checkoutData,
    deliveryTime,
    dues,
    prices,
    offer,
    payment,
    publicPhotoCarUrl,
    selectedColor,
    selectedModel,
    setCheckoutData,
    accessoriesTotalPrice,
    selectedAccessories,
    selections,
    surchargeAmount,
  ])

  // INSURANCE
  let insuranceRows: { name: string; value: string }[] = []
  const hasInsurance = Boolean(checkoutData.insurance?.selectedPlan)
  if (hasInsurance && financeData) {
    const { selectedPlan } = checkoutData.insurance
    insuranceRows = [
      {
        name: "Deducible",
        value: `${selectedPlan.deductible} UF`,
      },
      {
        name: "Aseguradora",
        value: insuranceCompanyName,
      },
      {
        name: "Valor cuota mensual",
        value: `$${parseNumber(
          Math.round(selectedPlan.monthlyCostUf * financeData.todayUF)
        )}`,
      },
    ]
  }

  //Seller Data
  const sellerRows = []
  const hasSeller = Boolean(checkoutData.sellerData)
  if (hasSeller) {
    sellerRows.push({
      name: "Atendido por",
      value: checkoutData.sellerData.name,
    })
  }

  const openInsuranceDialog = useCallback(() => setInsuranceDialogOpen(true), [
    setInsuranceDialogOpen,
  ])

  const closeInsuranceDialog = useCallback(
    () => setInsuranceDialogOpen(false),
    [setInsuranceDialogOpen]
  )

  const insuranceDialogPrimaryAction = useCallback(() => {
    closeInsuranceDialog()
    openInsuranceDrawer()
  }, [closeInsuranceDialog, openInsuranceDrawer])

  const onSubmitStore = useCallback(() => {
    closeInsuranceDialog()
    setDialog(true)
  }, [setDialog, closeInsuranceDialog])

  const removeInsurance = useCallback(() => {
    setCheckoutData(prev => ({ ...prev, insurance: null }))
    infoToSessionString({ insuranceSimulationId: null })
  }, [setCheckoutData])

  return (
    <>
      <SEO
        title="Estás a un paso de tu auto nuevo | DercoCenter X"
        url="https://dercocenterx.cl/checkout"
        description="Autos nuevos con los mejores precios y opciones de financiamiento. Puedes entregar tu auto en parte de pago, reservar en línea y recibir en tu domicilio."
      ></SEO>
      <Header />
      <CheckoutHeader />
      <div className="checkout standard-page">
        <div className="content">
          <div className="page-title">Resumen del auto</div>
          <div className="car-summary">
            <div className="image">
              {offer ? (
                <img
                  src={publicPhotoCarUrl}
                  alt=""
                  className={`car-image ${offer ? "offer" : ""}`}
                />
              ) : (
                <Image className="car-image" filename={colorImagePath}></Image>
              )}
              <div className="disclaimer">
                {offer
                  ? "Foto referencial"
                  : `Foto corresponde a versión ${getMostExpensiveVersionName(
                      selectedModel,
                      modelsPricesStocks
                    )}`}
              </div>
            </div>
            <div className="texts">
              <div className="name">
                {offer ? offer.modelName : selectedModel?.name}
              </div>
              <div className="name">
                {offer
                  ? offer.versionName
                  : getVersionName(selectedModel, selectedVersion?.versionId)}
              </div>

              <div className="selections">
                Auto nuevo | Año{" "}
                {offer ? offer.year : financeData?.otherParams?.newCarsYear}
              </div>

              <div className="selections">
                {!offer &&
                  getSelectionsSeparatedString(
                    selectedModel,
                    getSelectedOptions(selectedModel, selections)
                  )}
              </div>

              <div className="delivery-days">
                Tiempo de entrega: {deliveryTime} días
              </div>
            </div>
          </div>
          <DCXButton
            className={offer && !offer.technicalSheetUrl ? "hidden" : null}
            id="open-details-drawer-button-2"
            type="tertiary"
            text="CARACTERÍSTICAS DEL AUTO"
            arrow="right"
            onClick={
              offer
                ? () => window.open(offer.technicalSheetUrl)
                : openDetailsDrawer
            }
          />
          <Divider className="first-divider" />
          <div className="infos top">
            <div className="info one">
              <div className="left">
                <img src={checkoutMoneyImage} alt="" />
              </div>
              <div className="right">
                <div className="bold">Sin regateos ni presiones</div>
                <div className="desc">
                  Mostramos siempre el precio final incluyendo nuestros
                  descuentos
                </div>
              </div>
            </div>

            <div className="info two">
              <div className="left">
                <img src={checkoutDeliveryImage} alt="" />
              </div>
              <div className="right">
                <div className="bold">Pruébalo 7 días o 100 kms</div>
                <div className="desc">
                  Te lo llevamos a tu casa y si no te gusta ¡Lo devuelves!
                </div>
                <div className="desc small">No válido para outlet</div>
              </div>
            </div>
          </div>
          <Divider className="second-divider" />
          <div className="page-title">Resumen de pago</div>
          <PaymentSummaryTable
            appContext={appContext}
            prices={prices}
            isOffer={Boolean(offer)}
            PaymentParams={PaymentParams}
          />
          <DCXButton
            className="second"
            id="open-financing-drawer-button-checkout"
            type="tertiary"
            text="CAMBIAR FORMA DE PAGO"
            arrow="right"
            onClick={openFinancingDrawer}
          />
          {/* <Divider className="third-divider" /> */}
          <div className="infos bottom">
            <div className="info two">
              <div className="left">
                <img src={checkoutDeliveryImage} alt="" />
              </div>
              <div className="right">
                <div className="bold">Pruébalo 7 días o 100 kms</div>
                <div className="desc">
                  Te lo llevamos a tu casa y si no te gusta ¡Lo devuelves!
                </div>
                <div className="desc small">No válido para outlet</div>
              </div>
            </div>
          </div>
          <div className={`insurance-section ${!hasInsurance ? "hidden" : ""}`}>
            <div className="page-title">Derco Seguros</div>
            <DCXTable rows={insuranceRows} windowWidth={windowWidth} />
            <DCXButton
              className="remove-insurance-button"
              id="remove-checkout-insurance-button"
              type="tertiary"
              text="QUITAR SEGURO"
              // arrow="right"
              endIcon={<RemoveCircleOutlineIcon />}
              onClick={removeInsurance}
            />
          </div>
          <InsuranceShortcutCard
            className={hasInsurance ? "hidden" : ""}
            handleClick={openInsuranceDrawer}
          />
          <div className={`seller-section ${!hasSeller ? "hidden" : ""}`}>
            <div className="page-title">Asistencia</div>
            <DCXTable rows={sellerRows} windowWidth={windowWidth} />
          </div>
        </div>
        <div className="buttons">
          <DCXButton
            type="secondary"
            text="ATRÁS"
            onClick={() => {
              navigate(urlWithSession("/checkout/tax"), { replace: true })
            }}
          />
          <DCXButton
            type="primary"
            text="SIGUIENTE"
            onClick={
              !hasInsurance
                ? openInsuranceDialog
                : forStoresPages
                ? onSubmitStore
                : onSubmit
            }
          />
        </div>
      </div>
      <Footer />
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={process.env.GATSBY_RECAPTCHA_SITEKEY || ""}
        size="invisible"
        onChange={async () => null}
      />
      <InfoDialog
        title="Derco Seguros"
        primaryButtonText="COTIZAR"
        primaryButtonHandle={insuranceDialogPrimaryAction}
        secondaryButtonText="NO AHORA"
        secondaryButtonHandle={forStoresPages ? onSubmitStore : onSubmit}
        open={insuranceDialogOpen}
        handleClose={closeInsuranceDialog}
        dialogClass="checkout-insurance-dialog"
      >
        <span>
          ¿Quieres asegurar tu auto? Cotiza en línea y elige el que quieras
        </span>
      </InfoDialog>
      <ShareDialog
        emojiSrc={FacePartyIcon}
        mainText="Estás a un paso de tu próximo auto"
        qrText="Sigue desde tu celular"
        mailText="o desde tu correo"
        mailingTemplate="checkout"
        qrUrl={getCurrentUrlOnMainSiteForSharing({ withSearch: true })}
        open={dialogOpened}
        setState={setDialog}
        PaperProps={{
          style: {
            // position: "fixed",
            // top: "85px",
            // right: "110px",
            // height: "auto",
            minHeight: forIpadPage ? "480px" : "330px",
            width: "700px",
          },
        }}
        continueThisSiteText={
          forIpadPage && "Ingresa tus datos y reserva tu auto"
        }
        continueThisSiteAction={forIpadPage && onSubmit}
      />

      {loading && <Loader />}
    </>
  )
}

const differences = (checkoutData, PaymentParams) =>
  checkoutData.withCredit !== PaymentParams.withCredit ||
  checkoutData.carPrice !== PaymentParams.finalCarPrice ||
  checkoutData.firstPaymentPrice !== PaymentParams.firstPaymentPrice

export const query = graphql`
  query FULL_QUERY_4 {
    allFile {
      nodes {
        relativePath
        absolutePath
      }
    }
    allModels {
      nodes {
        ...ModelBasic
        ...Highlights
        ...Gallery
        ...HeroVideo
        ...RecommendVideo
        ...ColorGallery
        ...ModelStrings
        ...EngineSpecs
        ...DimensionSpecs
        ...PracticalData
        ...TechnicalData
        ...Versions
        ...ConfigOptions
      }
    }
  }
`

export default Checkout
